



















import Vue from 'vue'

interface Data {
  enter: number;
}

export default Vue.extend({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },

    accept: {
      type: Array,
      default: () => [],
      validator(value: unknown[]) {
        const isEveryString = value.every((v) => typeof v === 'string')
        if (!isEveryString) {
          console.error('"accept" property must contain only strings!')
        }
        return isEveryString
      },
    },
  },
  data(): Data {
    return {
      enter: 0,
    }
  },
  computed: {
    isOver(): boolean {
      return this.enter > 0
    },
  },
  methods: {
    onDragOver($event: any) {
      console.log('$event', $event)
      this.enter++
    },

    onDragExit($event: any) {
      console.log('$event', $event)
      this.enter--
    },

    onDrop(ev: DragEvent) {
      this.enter = 0

      if (ev.dataTransfer) {
        const { files } = ev.dataTransfer

        this.$emit('files-dropped', files)
      }
    },
  },
})
